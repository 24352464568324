
// el-ui
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// preview
import vue3PhotoPreview from 'vue3-photo-preview'
import 'vue3-photo-preview/dist/index.css'

// axios
import axios from './utils/request.js'

// wow.js
import 'animate.css'
import 'animate.css/animate.compat.css'

import 'normalize.css'

// swiper 样式
import 'swiper/scss'

// 公共样式
import './assets/css/master.scss'

// 视差
import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue'

// 初始化
import App from './App.vue'
import { createApp } from 'vue'

// 路由
import router from './router'
import store from './store'

const app = createApp(App)
app.config.globalProperties.$http = axios
app.component('scroll-parallax', ScrollParallax)
app.use(ElementPlus)
app.use(vue3PhotoPreview)
app.use(store).use(router).mount('#app')
