<template>
    <footer class="footer">
        <div class="footer_row">
            <div class="footer_info">
                <div class="footer_contact">
                    <div class="footer_contact_item">
                        <div class="footer_contact_item_icon">
                            <i class="iconfont">&#xe678;</i>
                        </div>
                        <div class="footer_contact_item_txt">
                           电话：{{data.companytel}}
                        </div>
                    </div>
                    <div class="footer_contact_item">
                        <div class="footer_contact_item_icon">
                            <i class="iconfont">&#xe605;</i>
                        </div>
                        <div class="footer_contact_item_txt">
                            邮箱：{{data.companyemail}}
                        </div>
                    </div>
                    <div class="footer_contact_item">
                    <div class="footer_contact_item_icon">
                            <i class="iconfont">&#xe769;</i>
                        </div>
                        <div class="footer_contact_item_txt">
                            地址：{{data.companyaddress}}
                        </div>
                    </div>
                </div>
                <div class="footer_nav">
                    <div
                        class="footer_nav_item"
                        v-for="itemA in navtList"
                        :key="itemA.catid"
                    >
                        <div class="footer_nav_a">
                            <router-link :to="{
                                path:itemA.biaoshi,
                                query:{
                                    catid:itemA.catid,
                                    cid:itemA.child[0].catid,
                                }
                            }" @click="TypeAni">
                                <span class="hoverable_txt">{{itemA.name}}</span>
                            </router-link>
                        </div>
                        <ul class="footer_nav_b" v-if="itemA.child.length > 1">
                            <li v-for="itemB in itemA.child" :key="itemB.catid">
                                <router-link :to="{
                                    path:itemB.biaoshi,
                                    query:{
                                    catid:itemA.catid,
                                    cid:itemB.catid
                                    }
                                }">
                                    <span>{{itemB.name}}</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>

            <div class="footerKeep">
                <div class="footerKeep_item">
                    <span>Copyright © 2022</span><span>成都华西临床研究中心有限公司</span>
                    <a href="https://beian.miit.gov.cn" target="_blank">{{data.icpno}}</a>
                </div>
                <div class="footerKeep_item">
                    <a href="https://www.28jw.cn" target="_blank">网站建设</a>
                    <span>：</span>
                    <a href="https://www.28jw.cn" target="_blank">今网科技</a>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import { reactive, getCurrentInstance, toRefs, inject } from 'vue'
export default {
  name: 'Footer',
  setup () {
    const navData = inject('navData')
    const datas = reactive({
      data: {},
      navtList: navData
    })
    const msg = {
      var: 'companytel,companyemail,companyaddress,icpno'
    }
    const $http = getCurrentInstance().appContext.config.globalProperties.$http
    $http.get('fdata/set', msg).then(res => {
      if (res.code === 1) {
        const obj = res.data
        obj.forEach((item, index) => {
          if (item.var === 'companyaddress') {
            datas.data.companyaddress = item.value
          } else if (item.var === 'companyemail') {
            datas.data.companyemail = item.value
          } else if (item.var === 'companytel') {
            datas.data.companytel = item.value
          } else if (item.var === 'icpno') {
            datas.data.icpno = item.value
          }
        })
      }
    })
    const TypeAni = () => {
      sessionStorage.setItem('typeAni', 1)
    }
    const { data, navtList } = toRefs(datas)
    return { data, navtList, TypeAni }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/style.scss';
.footer{
    background-color:$Background8;
    &_row{
        @include Row;
        padding-top:.2rem;
        .footer_info{
            padding:.8rem 0;
            @include Flex;
            @include flat-only{
                flex-wrap:wrap;
                padding:.5rem 0;
            }
            .footer_contact{
                padding-right:10%;
                @include minpc-only{
                    padding-right:5%;
                }
                @include flat-only{
                    width:100%;
                    padding-right:0;
                    order:2;
                    margin-top:.4rem;
                }
                @include mobile-only{
                    margin-top:.1rem;
                }
                &_item:nth-child(1)>.footer_contact_item_icon{
                    background-color:#6ed1f5;
                }
                &_item:nth-child(2)>.footer_contact_item_icon{
                    background-color:#0f90da;
                }
                &_item:nth-child(3)>.footer_contact_item_icon{
                    background-color:#1a70d2;
                }
                &_item:last-child{
                    margin-bottom:0;
                }
                &_item{
                    @include Flex;
                    align-items:center;
                    margin-bottom:.1rem;
                    &_icon{
                        width:.3rem;
                        height:.3rem;
                        @include TextAlign(center);
                        line-height:.3rem;
                        @include BorderRadius(50%);
                        color: $Color;
                        margin-right: .14rem;
                        >i{
                            font-size:.18rem;
                        }
                    }
                    &_txt{
                        font-size:.16rem;
                        color:$Color4;
                        max-width: 3.4rem;
                        line-height:1.5;
                        @include pc-only{
                            max-width: 2.8rem;
                        }
                        @include minpc-only{
                            max-width: 2rem;
                        }
                        @include flat-only{
                            font-size:.14rem;
                            max-width:100%;
                        }
                    }
                }
            }
            .footer_nav{
                @include Flexitem;
                @include Flex;
                justify-content: space-between;
                &_item:first-child{
                    padding-left:0;
                }
                @include flat-only{
                    width:100%;
                }
                @include mobile-only{
                    flex-wrap:wrap;
                }
                &_item{
                    padding-left:4%;
                    @include minpc-only{
                        padding-left:2%;
                    }
                    @include mobile-only{
                        width:50%;
                        padding-left:0;
                        margin-bottom:.3rem;
                    }
                    .footer_nav_a{
                        padding-bottom:.12rem;
                        a{
                            font-size:.18rem;
                            color:$Color3;
                            line-height:1.6;
                            font-weight:bold;
                            .hoverable_txt::after{
                                background-color:$Color3;
                            }
                            @include LineAni;
                            @include flat-only{
                                font-size:.16rem
                            }
                        }
                    }
                    .footer_nav_b>li:last-child{
                        padding-bottom:0;
                    }
                    .footer_nav_b>li{
                        a{
                            padding:.05rem 0;
                            line-height:1.4;
                            font-size:.16rem;
                            color:$Color4;
                            display:inline-block;
                            @include flat-only{
                                font-size:.14rem
                            }
                            >span{
                                transition:all .35s;
                                -webkit-transition:all .35s;
                                display:block;
                            }
                            &:hover{
                                color:$Color6;
                                >span{
                                    transform:translateX(5px);
                                    -webkit-transform:translateX(5px);
                                }
                            }
                        }
                    }
                }
            }
        }
        .footerKeep{
            @include Flex;
            position: relative;
            width:100%;
            padding:.25rem 0;
            line-height:1.6;
            font-size:.16rem;
            @include flat-only{
               padding:.15rem 0;
               font-size:.14rem;
            }
            @include mobile-only{
               font-size:.13rem;
            }
            &::before{
                content: '';
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:1px;
                background-color:#dbdbdb;
            }
            &_item:nth-child(2){
                margin-left:auto;
                min-width:1.5rem;
                text-align:right;
            }
            span{
                margin-right:.1rem;
            }
            a,span{
                color:$Color4;
            }
        }
    }
}
</style>
